import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText, Typography, Chip } from "@material-ui/core";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { i18n } from "../../translate/i18n";

const TicketsQueueSingleSelect = ({
  userQueues,
  selectedQueueId,
  onChange,
  type
}) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  const useStyles = makeStyles(theme => ({
    menuSelect: {
      "& .MuiSelect-outlined": {
        paddingTop: "8px",
        paddingBottom: "8px"
      }
    }
  }));
  const classes = useStyles();
  return (
    <div style={{ paddingBottom: "15px" }}>
      <FormControl fullWidth >
        {userQueues?.length > 1 &&
          <Select
            displayEmpty
            variant="outlined"
            className={classes.menuSelect}
            style={{ borderRadius: '8px', fontSize: '13px', fontFamily: 'Plus Jakarta Sans', color: "#5A6A9D", backgroundColor: "white" }}
            value={selectedQueueId}
            placeholder="Mis Areas"
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={() => {
              if (userQueues[0]) {
                return userQueues[0].name
              } else {
                return i18n.t("ticketsQueueSelect.placeholder")
              }
            }}
          >
            {userQueues.map(queue => (
              <MenuItem dense key={queue.id} value={queue.id}>
                <Checkbox
                  style={{
                    color: queue.color,
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueId === queue.id}
                />
                <ListItemText primary={queue.name} />
              </MenuItem>
            ))}
          </Select>
        }
        {userQueues?.length === 1 &&
          <Typography sx={{ mr: 'auto', pl: '8px', color: "#5A6A9D" }} level="title-lg">
            Enviar como: <Chip variant="outlined" size="small" label={userQueues[0].name} color="info" />
          </Typography>
        }
      </FormControl>
    </div>
  );
};

export default TicketsQueueSingleSelect;