import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import './TicketHome.css';
import NewTicketModal from "../../components/NewTicketModal";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { ReactComponent as ReactLogo } from '../../assets/chatp_conversation_logo.svg'


const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // padding: theme.spacing(4),
    height: `calc(100% )`,
    overflowY: "hidden",
  },
  chatBanner: {

    window: "100px"
  },
  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Chat = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [newTicketModalOpen, setNewTicketModalOpen] = React.useState(false);


  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            <TicketsManager />
          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {ticketId ? (
              <>
                <Ticket />
              </>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    onClose={(e) => setNewTicketModalOpen(false)}
                  />
                  <div className={classes.chatBanner}>

                    <ReactLogo />

                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        ¡ Escribe a un contacto!
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {i18n.t("chat.noTicketMessage")}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {/* <Button disabled="true" size="small">Facebook</Button>
                        <Button disabled="true" size="small">Instagram</Button> */}

                    </CardActions>
                    <Button variant="contained" onClick={() => setNewTicketModalOpen(true)} size="small">Iniciar nuevo chat</Button>
                  </div>
                  {/* <span>aaaaa {i18n.t("chat.noTicketMessage")}</span> */}
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;
